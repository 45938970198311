import { SetStateAction } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { Reservation } from "../../types/Reservations";
import {
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { useTranslation } from "react-i18next";
import moment from "moment";
import MemberProfile from "../../components/common/MemberProfile";

export default function ReservationDetails({
  isOpen,
  setIsOpen,
  reservation,
}: {
  isOpen: boolean;
  setIsOpen: (value: SetStateAction<boolean>) => void;
  reservation: Reservation;
}) {
  const { t } = useTranslation();
  const toggleDrawer = (newOpen: SetStateAction<boolean>) => () => {
    setIsOpen(newOpen);
  };

  const DrawerContent = (
    <Box sx={{ width: 550 }} role="presentation">
      <Container sx={{ padding: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} textAlign="right">
            <IconButton onClick={toggleDrawer(false)}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Typography variant="h6">{t("reservations.titleDetail")}</Typography>
        <Paper sx={{ padding: 2, marginTop: 4 }} elevation={1}>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="body1">{t("reservations.date")}</Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
              <Tooltip
                title={moment(reservation.date).fromNow()}
                placement="top-end"
              >
                <Typography variant="body2">
                  {moment(reservation.date).format("MMM DD, YYYY")}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        </Paper>
        <Paper sx={{ padding: 2, marginTop: 4 }} elevation={1}>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="body1">{t("reservations.time")}</Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
              <Typography variant="body2">
                {moment(reservation.date).format("h:mm A")}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
        <Paper sx={{ padding: 2, marginTop: 4 }} elevation={1}>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="body1">
                {t("reservations.guests")}
              </Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
              <Typography variant="body2">{reservation.guests}</Typography>
            </Grid>
          </Grid>
        </Paper>
        <Paper sx={{ padding: 2, marginTop: 4 }} elevation={1}>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="body1">{t("reservations.table")}</Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
              <Typography variant="body2">{reservation.table}</Typography>
            </Grid>
          </Grid>
        </Paper>
        <Paper sx={{ padding: 2, marginTop: 4 }} elevation={1}>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="body1">
                {t("reservations.occasion")}
              </Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
              <Typography variant="body2">{reservation.occasion}</Typography>
            </Grid>
          </Grid>
        </Paper>
        <Paper sx={{ padding: 2, marginTop: 4 }} elevation={1}>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="body1">
                {t("reservations.specialRequests")}
              </Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
              <Typography variant="body2">
                {reservation.specialRequests}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
        <Paper sx={{ padding: 2, marginTop: 4 }} elevation={1}>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="body1">
                {t("reservations.allergies")}
              </Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
              <Typography variant="body2">{reservation.allergies}</Typography>
            </Grid>
          </Grid>
        </Paper>
      </Container>
      <Divider sx={{ marginTop: 4 }} />
      <Container sx={{ padding: 2 }}>
        <Typography variant="body1" paddingY={2}>
          {t("reservations.member")}
        </Typography>
        <MemberProfile
          title={reservation.name}
          subTitle={reservation.email}
          picture={reservation.picture}
        />
      </Container>
      <Divider sx={{ marginTop: 2 }} />
      <Container sx={{ padding: 2 }}>
        <Button color="primary">{t("reservations.contactMember")}</Button>
      </Container>
    </Box>
  );

  return (
    <div>
      <Drawer open={isOpen} onClose={toggleDrawer(false)} anchor="right">
        {DrawerContent}
      </Drawer>
    </div>
  );
}
