import {
  Box,
  Button,
  Container,
  IconButton,
  InputBase,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  ChangeEvent,
  SyntheticEvent,
  useContext,
  useRef,
  useState,
} from "react";

import { AppContext } from "../../context/AppContext";
import AccountHeader from "./AccountHeader";
import NewMenuModal from "../../components/profile/NewMenuModal";
import ConfirmModal from "../../components/common/ConfirmModal";
import NoData from "../../components/common/NoData";

const dummyMenuItems: MenuItem[][] = [
  [
    {
      id: 1,
      name: "Spaghetti",
      description: "Spaghetti with tomato sauce",
      price: 12.99,
    },
    {
      id: 2,
      name: "Cheeseburger",
      description: "Cheeseburger with fries",
      price: 8.99,
    },

    {
      id: 4,
      name: "Apple pie",
      description: "Apple pie with ice cream",
      price: 4.99,
    },
  ],
  [
    {
      id: 9,
      name: "Caesar salad",
      description: "Caesar salad with chicken",
      price: 7.99,
    },
  ],
  [
    {
      id: 3,
      name: "Coca Cola",
      description: "Coca Cola",
      price: 2.99,
    },
    {
      id: 11,
      name: "Orange juice",
      description: "Orange juice",
      price: 1.99,
    },
  ],
  [
    {
      id: 5,
      name: "Chocolate cake",
      description: "Chocolate cake",
      price: 5.99,
    },
    {
      id: 6,
      name: "Ice cream",
      description: "Ice cream",
      price: 3.99,
    },
  ],
];

const CustomTab: any = styled((props) => <Tab disableRipple {...props} />)(
  () => ({
    minWidth: 0,
  }),
);

function CustomTabPanel(props: {
  children: React.ReactNode;
  value: number;
  index: number;
}) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

interface MenuItem {
  id: number;
  name: string;
  description: string;
  price: number;
}

type MenuItemKey = keyof MenuItem;
const tableHeaders: { key: MenuItemKey; title: string }[] = [
  {
    key: "id",
    title: "menu.tableId",
  },
  {
    key: "name",
    title: "menu.tableName",
  },
  {
    key: "description",
    title: "menu.tableDescription",
  },
  {
    key: "price",
    title: "menu.tablePrice",
  },
];

export default function Menu() {
  const { t } = useTranslation();
  const [tab, setTab] = useState(0);
  const [isNewMenuModalOpen, setIsNewMenuModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { restaurant } = useContext(AppContext);
  const inputRef = useRef(null);
  const [categories, setCategories] = useState([
    {
      id: 1,
      title: "Full course meals",
    },
    {
      id: 2,
      title: "Appetizers",
    },
    {
      id: 3,
      title: "Drinks",
    },
    {
      id: 4,
      title: "Desserts",
    },
  ]);

  const [menuItems, setMenuItems] = useState<MenuItem[][]>(dummyMenuItems);

  const handleMenuItemChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    categoryIndex: number,
    index: number,
    key: MenuItemKey,
  ) => {
    if (key === "id") {
      return;
    }

    setMenuItems((menuItems) =>
      menuItems.map((item, idx) => {
        if (idx === categoryIndex) {
          return item.map((row, rowIndex) => {
            if (rowIndex === index) {
              return {
                ...row,
                [key]: e.target.value,
              };
            }
            return row;
          });
        }
        return item;
      }),
    );
  };

  function tabProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  function handleRemoveMenuItem(categoryIndex: number, index: number) {
    setMenuItems((menuItems) =>
      menuItems.map((item, idx) => {
        if (idx === categoryIndex) {
          return item.filter((_, rowIndex) => rowIndex !== index);
        }
        return item;
      }),
    );
  }

  function addMenuItem(categoryIndex: number) {
    menuItems[categoryIndex] = menuItems[categoryIndex] ?? [];
    const newMenuItem: MenuItem = {
      id: (menuItems[categoryIndex].at(-1)?.id ?? 0) + 1,
      name: "",
      description: "",
      price: 10.99,
    };

    setMenuItems((menuItems) =>
      menuItems.map((item, index) => {
        if (index === categoryIndex) {
          return [...item, newMenuItem];
        }
        return item;
      }),
    );

    setTimeout(() => {
      (inputRef?.current as any)?.childNodes?.[0]?.focus?.();
    }, 250);
  }

  const handleChange = (event: SyntheticEvent, tab: number) => {
    setTab(tab);
  };

  const handleDeleteTab = (tab: number) => {
    setCategories(categories.filter((_, index) => index !== tab));
    setTab(tab > 0 ? tab - 1 : 0);
    setIsDeleteModalOpen(false);
  };

  const createNewCategory = (e: SyntheticEvent) => {
    handleChange(e, categories.length - 1);
    setIsNewMenuModalOpen(true);
  };

  const createMenu = (name: string) => {
    setCategories([...categories, { id: categories.length + 1, title: name }]);
    setTab(categories.length);
    setIsNewMenuModalOpen(false);
  };

  if (!restaurant) {
    return null;
  }

  return (
    <div>
      <AccountHeader />
      <Container maxWidth="xl">
        {categories.length === 0 ? (
          <NoData
            topMargin="10%"
            title={t("menu.noDataTitle")}
            message={t("menu.noDataText")}
            action={() => setIsNewMenuModalOpen(true)}
            actionTitle={t("menu.addMenu")}
          />
        ) : (
          <Typography variant="h6">{t("menu.title")}</Typography>
        )}
        {categories.length > 0 && (
          <Box
            sx={{ borderBottom: 1, borderColor: "divider" }}
            position="relative"
          >
            <Tabs
              value={tab}
              onChange={handleChange}
              aria-label={t("menu.title")}
            >
              {categories.map((category, index) => (
                <CustomTab
                  label={category.title}
                  key={index}
                  {...tabProps(index)}
                />
              ))}
              <CustomTab
                icon={<AddIcon />}
                {...tabProps(categories.length)}
                onClick={createNewCategory}
              />
            </Tabs>
            <IconButton
              sx={{ position: "absolute", right: 0, top: 0 }}
              onClick={() => setIsDeleteModalOpen(true)}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        )}
        {categories.length > 0 && (
          <CustomTabPanel value={tab} index={tab}>
            <TableContainer>
              <Table aria-label={categories[tab].title}>
                <TableHead>
                  <TableRow>
                    {tableHeaders.map((header) => (
                      <TableCell key={header.key}>{t(header.title)}</TableCell>
                    ))}
                    <TableCell>&nbsp;</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {menuItems[tab]?.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      {tableHeaders.map((header) => (
                        <TableCell
                          key={`${header.key}-${index}-cell`}
                          scope="row"
                        >
                          <div className="flex items-center gap-1">
                            <Typography variant="body1">
                              {header.key === "price"
                                ? restaurant.currencySymbol
                                : ""}
                            </Typography>
                            <InputBase
                              id={`${header.key}-${index}-input`}
                              margin="none"
                              value={row[header.key]}
                              sx={{ p: 0, m: 0 }}
                              fullWidth={true}
                              placeholder={
                                header.key !== "id"
                                  ? t(`menu.${header.key}Placeholder`)
                                  : ""
                              }
                              disabled={header.key === "id"}
                              ref={
                                index + 1 === menuItems[tab]?.length &&
                                header.key === "name"
                                  ? inputRef
                                  : null
                              }
                              onChange={(e) =>
                                handleMenuItemChange(e, tab, index, header.key)
                              }
                            />
                          </div>
                        </TableCell>
                      ))}
                      <TableCell>
                        <IconButton
                          onClick={() => handleRemoveMenuItem(tab, index)}
                          aria-label={t("common.delete")}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell colSpan={5}>
                      <Button
                        startIcon={<AddIcon />}
                        onClick={() => addMenuItem(tab)}
                        type="button"
                        color="secondary"
                      >
                        {t("menu.addMenuItem")}
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </CustomTabPanel>
        )}

        <NewMenuModal
          save={createMenu}
          isOpen={isNewMenuModalOpen}
          handleClose={() => setIsNewMenuModalOpen(false)}
        />
        {isDeleteModalOpen && (
          <ConfirmModal
            title={t("menu.delete")}
            inputIsOpen={isDeleteModalOpen}
            inputHandleClose={() => setIsDeleteModalOpen(false)}
            message={t("menu.deleteMessage")}
            handleConfirm={() => handleDeleteTab(tab)}
          />
        )}
      </Container>
    </div>
  );
}
