import { Grid, Typography } from "@mui/material";

export default function MemberProfile({
  title,
  subTitle,
  picture,
}: {
  title: string;
  subTitle: string;
  picture: string;
}) {
  return (
    <Grid container>
      <Grid item paddingRight={1}>
        <img src={picture} alt={title} className="rounded-full w-14" />
      </Grid>
      <Grid item xs={9}>
        <Typography variant="body1" noWrap>
          {title}
        </Typography>
        <Typography variant="body2" noWrap>
          {subTitle}
        </Typography>
      </Grid>
    </Grid>
  );
}
