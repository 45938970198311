export interface Restaurant {
  name: string;
  address: string;
  phone: string;
  email: string;
  cuisine: string;
  website: string;
  currencySymbol: string;
  priceRange: number;
  location: string;
  cancellationPolicy: string;
  arrivalInstructions: string;
  bio: string;
  resyId?: string;
  about: string;
  photos: {
    img: string;
    title: string;
  }[];
}

export const RestaurantData: Restaurant = {
  name: "Don Agie",
  address: "1234 Main St, City, Country",
  phone: "+1234567890",
  email: "test@example.com",
  cuisine: "Chinese",
  currencySymbol: "$",
  priceRange: 3,
  location: "West Village",
  website: "https://example.com",
  about:
    "Don Angie remains on permanent Notify status for many, and for good reason. Since opening in 2017, the West Village hit has won over diners with... More",
  cancellationPolicy:
    "Cancel 24 hours in advance. At Don Angie, we strive to provide a memorable dining experience for  ....",
  arrivalInstructions: "Please arrive 15 minutes before your reservation time.",
  bio: "At Don Angie, we strive to provide a memorable dining experience for te...",
  photos: [
    {
      img: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
      title: "Breakfast",
    },
    {
      img: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
      title: "Burger",
    },
    {
      img: "https://images.unsplash.com/photo-1522770179533-24471fcdba45",
      title: "Camera",
    },
    {
      img: "https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c",
      title: "Coffee",
    },
    {
      img: "https://images.unsplash.com/photo-1533827432537-70133748f5c8",
      title: "Hats",
    },
    {
      img: "https://images.unsplash.com/photo-1558642452-9d2a7deb7f62",
      title: "Honey",
    },
    {
      img: "https://images.unsplash.com/photo-1516802273409-68526ee1bdd6",
      title: "Basketball",
    },
    {
      img: "https://images.unsplash.com/photo-1518756131217-31eb79b20e8f",
      title: "Fern",
    },
    {
      img: "https://images.unsplash.com/photo-1597645587822-e99fa5d45d25",
      title: "Mushrooms",
    },
    {
      img: "https://images.unsplash.com/photo-1567306301408-9b74779a11af",
      title: "Tomato basil",
    },
    {
      img: "https://images.unsplash.com/photo-1471357674240-e1a485acb3e1",
      title: "Sea star",
    },
    {
      img: "https://images.unsplash.com/photo-1589118949245-7d38baf380d6",
      title: "Bike",
    },
  ],
};
