import { Box, Container, Typography } from "@mui/material";

import "./Home.css";
import { useContext } from "react";
import Notifications from "../../components/shared/Notifications";
import { AppContext } from "../../context/AppContext";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";

export default function Home() {
  const { t } = useTranslation();
  const { restaurant, currentUser } = useContext(AppContext);

  return (
    <div>
      <Notifications restaurant={restaurant} />
      <Container maxWidth="xl">
        <Box marginTop={4}>
          <Typography variant="h5" className="mt-4">
            {t("home.welcome")}&nbsp;🎉
          </Typography>
          <Typography variant="body2" className="mt-0">
            {t("home.welcomeText")}
          </Typography>

          {!currentUser && <Navigate to="/login" />}
        </Box>
      </Container>
    </div>
  );
}
