import { t } from "i18next";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { FormEvent, useContext, useEffect, useRef, useState } from "react";
import ErrorIcon from "@mui/icons-material/Error";
import { initKeycloak, InitKeycloakParams } from "../../../keycloak";
import { AppContext, User } from "../../context/AppContext";
import { KEYCLOAK_REALM, LocalStorageKeys, UIMode } from "../../constants";
import logoLight from "../../../assets/images/logo-light.svg";
import logoDark from "../../../assets/images/logo-dark.svg";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState("");
  const { setCurrentUser, uiMode } = useContext(AppContext);
  const didInit = useRef(false);

  const initAuth = async () => {
    const token = localStorage.getItem(LocalStorageKeys.Token);
    const refreshToken = localStorage.getItem(LocalStorageKeys.RefreshToken);
    console.log("Token:", token, refreshToken);
    if (!token || !refreshToken) {
      return;
    }

    const { keycloak } = await initKeycloak({
      access_token: token,
      refresh_token: refreshToken,
    });

    setCurrentUser!(keycloak.tokenParsed! as User);
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      setError("");
      const res = await fetch(
        `realms/${KEYCLOAK_REALM}/protocol/openid-connect/token`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams({
            username: email,
            password: password,
            grant_type: "password",
            client_id: KEYCLOAK_REALM,
          }),
        },
      );

      if (!res.ok) {
        return setError(res.statusText);
      }

      const data: InitKeycloakParams = await res.json();
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { keycloak } = await initKeycloak(data);
      localStorage.setItem(LocalStorageKeys.Token, data.access_token);
      localStorage.setItem(LocalStorageKeys.RefreshToken, data.refresh_token);
      console.log("Data:", { data, res });
    } catch (error) {
      console.error("Login failed", error);
      setError((error as Error).message ?? "Login failed");
    }
  };

  useEffect(() => {
    if (!didInit.current) {
      initAuth();
      didInit.current = true;
    }
  });

  const logo = uiMode === UIMode.Dark ? logoDark : logoLight;
  return (
    <div className="m-[10%_auto] max-w-[690px]">
      <Box className="px-20 sm:m-2">
        <Box textAlign={"center"}>
          <img src={logo} alt="Logo" className="w-11 mx-auto mb-10" />
          <Typography
            variant="h2"
            marginBottom={2}
            fontFamily="Kessler"
            flexWrap="wrap-reverse"
          >
            {t("auth.loginTitle")}
          </Typography>
          <Typography variant="body1" marginBottom={4} marginX={7}>
            {t("auth.loginDescription")}
          </Typography>
        </Box>
        <form onSubmit={handleSubmit}>
          <TextField
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            label={t("auth.email")}
            required={true}
            variant="outlined"
            fullWidth
            placeholder="email@example.com"
            margin="normal"
          />

          <TextField
            id="password"
            label={t("auth.password")}
            variant="outlined"
            type="password"
            required={true}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            placeholder="*************"
            margin="normal"
          />

          <div className="my-4 flex align-middle items-center">
            <div className="flex-1">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={rememberMe}
                    onChange={(e, checked) => setRememberMe(checked)}
                    name="jason"
                  />
                }
                label={t("auth.rememberMe")}
              />
            </div>
            <Link href="#" variant="body1">
              {t("auth.forgotPassword")}
            </Link>
          </div>

          <p className="mt-8 text-right">
            <Button
              variant="contained"
              type="submit"
              color="primary"
              sx={{ width: "100%" }}
            >
              {t("auth.login")}
            </Button>
          </p>

          {error && (
            <Alert
              icon={<ErrorIcon fontSize="inherit" />}
              className="mt-4"
              severity="error"
            >
              {t("auth.loginFailed")}: {error}
            </Alert>
          )}
        </form>
      </Box>
    </div>
  );
}
