import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter } from "react-router-dom";
import "moment/locale/en-gb";
import "moment/locale/de";

import "./assets/fonts/kessler/stylesheet.css";
import "./assets/fonts/enduro/stylesheet.css";

import "./index.css";
import "./setupTranslations";
import { keycloak } from "./keycloak";
import reportWebVitals from "./reportWebVitals";

import Home from "./app/pages/home/Home";
import Reservations from "./app/pages/reservations/Reservations";
import AppContainer from "./app/components/shared/AppContainer";
import Login from "./app/pages/auth/Login";
import NotFound from "./app/pages/NotFound";
import Profile from "./app/pages/account/Profile";
import PhotoGallery from "./app/pages/account/PhotoGallery";
import Menu from "./app/pages/account/Menu";
import ProfileEdit from "./app/pages/account/ProfileEdit";

import ProtectedRoute from "./app/components/shared/ProtectedRoute";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <NotFound />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    element: <ProtectedRoute isAuthenticated={keycloak?.authenticated} />,
    children: [
      {
        path: "/reservations",
        element: <Reservations />,
      },
      {
        path: "/account",
        children: [
          {
            path: "/account/profile",
            element: <Profile />,
          },
          {
            path: "/account/menu",
            element: <Menu />,
          },
        ],
      },
      {
        path: "/account/profile/photo-gallery",
        element: <PhotoGallery />,
      },
      {
        path: "/account/profile/edit",
        element: <ProfileEdit />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <React.StrictMode>
    <AppContainer router={router} />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
