import { useEffect, useRef, useState } from "react";
import Keycloak from "keycloak-js";
import { RouterProvider, RouterProviderProps } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { CircularProgress, Paper, useMediaQuery } from "@mui/material";

import { AppContext, User } from "../../context/AppContext";
import NavigationBar from "./NavigationBar";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
// import { initKeycloak } from "../../../keycloak";
import logoLight from "../../../assets/images/logo-light.svg";
import logoDark from "../../../assets/images/logo-dark.svg";
import { UIMode } from "../../constants";
import { useTranslation } from "react-i18next";
import moment from "moment";
import i18next from "i18next";
import { Restaurant, RestaurantData } from "../../types/Restaurant";

const client = new ApolloClient({
  uri: "https://flyby-router-demo.herokuapp.com/",
  cache: new InMemoryCache(),
});

export default function AppContainer({ router }: RouterProviderProps) {
  const { t } = useTranslation();
  const didInit = useRef(false);
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const [currentUser, setCurrentUser] = useState<User | undefined>();
  const [restaurant, setRestaurant] = useState<Restaurant | undefined>();
  const keycloak = useRef<Keycloak | undefined>();
  const authInitializeError = useRef<Error | undefined>();

  let currentUiMode = prefersDarkMode ? UIMode.Dark : UIMode.Light;
  const [uiMode, setUiMode] = useState(currentUiMode);
  moment.locale(i18next.language);
  if (!restaurant) {
    setRestaurant(RestaurantData);
  }

  useEffect(() => {
    if (currentUiMode !== uiMode) {
      setUiMode(currentUiMode);
    }

    if (!didInit.current) {
      // setUiMode!(prefersDarkMode ? UIMode.Dark : UIMode.Light);
      // initKeycloak()
      //   .then(({ keycloak: kc, auth}) => {
      //     keycloak.current = kc;
      //     if (kc.authenticated) {
      //       setCurrentUser(kc.tokenParsed as User);
      //     } else {
      //       // kc.login({ });
      //     }

      //     console.log(kc);
      //   })
      //   .catch((error) => (authInitializeError.current = error));

      didInit.current = true;
    }
  }, [currentUiMode, uiMode]);

  const theme = createTheme({
    typography: {
      fontFamily: "Enduro",
      button: {
        textTransform: "none",
        backgroundColor: "none",
      },
    },
    palette: {
      primary: {
        main: uiMode === UIMode.Dark ? "#fff" : "#000",
        light: uiMode === UIMode.Dark ? "#000" : "#fff",
        dark: uiMode === UIMode.Dark ? "#a6a6a6" : "#6a6a6a",
      },
      action: {
        active: uiMode === UIMode.Dark ? "#fff" : "#000",
      },
      background: {
        default: uiMode === UIMode.Dark ? "#000" : "#F9FAFB",
        paper: uiMode === UIMode.Dark ? "#111" : "#fff",
      },
      secondary: {
        main: uiMode === UIMode.Dark ? "#a6a6a6" : "#6a6a6a",
        light: uiMode === UIMode.Dark ? "#6a6a6a" : "#a6a6a6",
        dark: uiMode === UIMode.Dark ? "#6a6a6a" : "#a6a6a6",
      },
      mode: uiMode,
    },
  });

  const authInitialized = keycloak.current?.authenticated ?? true;
  const logo = uiMode === UIMode.Dark ? logoDark : logoLight;

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {authInitialized ? (
          <AppContext.Provider
            value={{
              uiMode,
              currentUser,
              restaurant,
              setCurrentUser,
              setUiMode,
            }}
          >
            <main>
              <NavigationBar />
              <RouterProvider router={router} />
            </main>
          </AppContext.Provider>
        ) : authInitializeError.current ? (
          <Paper elevation={3} className="p-8 m-[10%_auto] w-[40%] text-center">
            <p>{t("auth.authError")}</p>
            <p>{authInitializeError.current?.message}</p>
          </Paper>
        ) : (
          <div className="m-[20%_auto] w-[100%] text-center">
            <img
              src={logo}
              alt="ACCESS"
              width={120}
              className="m-[10px_auto_40px]"
            />
            <CircularProgress
              color="inherit"
              aria-label={t("common.loading")}
            />
          </div>
        )}
      </ThemeProvider>
    </ApolloProvider>
  );
}
