import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Box, Container, Typography } from "@mui/material";
import Notifications from "../../components/shared/Notifications";
import { useContext } from "react";
import { AppContext } from "../../context/AppContext";

const menuItems = [
  {
    title: "account.profileTitle",
    to: "/account/profile",
  },
  {
    title: "account.menuTitle",
    to: "/account/menu",
  },
];

export default function AccountHeader() {
  const { t } = useTranslation();
  const { restaurant } = useContext(AppContext);
  const { pathname } = window.location;

  return (
    <div className="mb-10">
      <Container maxWidth="xl">
        <Box className="flex gap-2 my-4">
          {menuItems.map((item, index) => (
            <Link key={index} to={item.to}>
              <Typography
                variant="body1"
                fontWeight={pathname.startsWith(item.to) ? 700 : 300}
              >
                {t(item.title)}
              </Typography>
            </Link>
          ))}
        </Box>
      </Container>
      <Notifications restaurant={restaurant} />
    </div>
  );
}
