import { Box, Typography } from "@mui/material";
import EastIcon from "@mui/icons-material/East";
import { Restaurant } from "../../types/Restaurant";
import { useTranslation } from "react-i18next";

const ResyConnect = function () {
  const { t } = useTranslation();
  return (
    <Box
      component="section"
      role="button"
      bgcolor="#6A6A6A"
      color="#fff"
      className="mb-4 text-center p-3"
    >
      <div className="flex gap-2 justify-center">
        <div className="flex self-center">
          <Typography variant="body1" color="inherit">
            {t("account.hintResiConnect")}
          </Typography>
        </div>
        <div className="flex self-center">
          <EastIcon color="inherit" sx={{ fontSize: 20 }} />
        </div>
      </div>
    </Box>
  );
};

export default function Notifications({
  restaurant,
}: {
  restaurant?: Restaurant;
}) {
  return <div>{!restaurant?.resyId && <ResyConnect />}</div>;
}
