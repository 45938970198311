import { SetStateAction, SyntheticEvent, useContext, useState } from "react";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

import Drawer from "@mui/material/Drawer";
import {
  Container,
  Grid,
  IconButton,
  ImageListItem,
  ImageListItemBar,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import LocationPreview from "../../../assets/images/location-preview.png";
import { useTranslation } from "react-i18next";
import { Restaurant } from "../../types/Restaurant";
import { UIMode } from "../../constants";
import { AppContext } from "../../context/AppContext";

function CustomTabPanel(props: {
  children: React.ReactNode;
  value: number;
  index: number;
}) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: 2, px: 1 }}>{children}</Box>}
    </div>
  );
}

const CustomTab: any = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    fontSize: theme.typography.pxToRem(10),
    marginRight: theme.spacing(1),
    padding: 0,
    minWidth: 40,
    "&.Mui-selected": {},
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
  }),
);

const CustomTabs: any = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    // backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    width: "100%",
    paddding: 0,
    // backgroundColor: "#fff",
  },
});

const MobilePreview = ({
  restaurant,
  bgColor,
}: {
  restaurant: Restaurant;
  bgColor: string;
}) => {
  const { t } = useTranslation();
  const [tab, setTab] = useState(0);
  const handleChange = (event: SyntheticEvent, tab: number) => {
    setTab(tab);
  };

  const {
    photos: [photo],
  } = restaurant;
  const textStyle = { fontSize: 10 };
  const tabs = [
    {
      id: 1,
      title: t("preview.aboutTab"),
      disabled: false,
    },
    {
      id: 2,
      title: t("preview.menuTab"),
      disabled: false,
    },
  ];

  return (
    <Box bgcolor={bgColor} height="100%">
      <ImageListItem sx={{ height: 254 }}>
        <img
          srcSet={`${photo.img}?w=254&h=254&fit=crop&auto=format&dpr=2 2x`}
          src={`${photo.img}?w=254&h=254&fit=crop&auto=format`}
          alt={photo.title}
          loading="lazy"
        />
      </ImageListItem>
      <Box marginY={2} marginX={1}>
        <Box>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="body2">{restaurant.name}</Typography>
            </Grid>
            <Grid item xs={6}></Grid>
          </Grid>
          <div className="flex gap-1 mt-1">
            <Typography sx={textStyle} variant="body2">
              {restaurant.location}
            </Typography>
            <Typography sx={textStyle} variant="caption">
              &middot;
            </Typography>
            <Typography sx={textStyle} variant="body2">
              {restaurant.cuisine}
            </Typography>
          </div>
        </Box>
        <Box>
          <CustomTabs
            onChange={handleChange}
            value={tab}
            aria-label={t("preview.title")}
          >
            {tabs.map((tab, index) => (
              <CustomTab
                label={tab.title}
                key={index}
                id={`simple-tab-${index}`}
                disabled={tab.disabled}
                aria-controls={`simple-tabpanel-${index}`}
              />
            ))}
          </CustomTabs>
        </Box>
        <Box>
          <CustomTabPanel value={tab} index={0}>
            <Typography variant="body2" mb={1} style={textStyle}>
              {restaurant.about}
            </Typography>
            <ImageListItem>
              <img
                srcSet={`${LocationPreview}?w=502&h=194&fit=crop&auto=format&dpr=2 2x`}
                src={`${LocationPreview}?w=502&h=194&fit=crop&auto=format`}
                alt="Location"
                loading="lazy"
              />
              <ImageListItemBar
                sx={{
                  background: "rgba(0, 0, 0, 0.7)",
                  color: "#fff",
                  padding: 1,
                }}
                position="bottom"
                actionIcon={
                  <Typography variant="body2" style={textStyle}>
                    {restaurant.address}
                  </Typography>
                }
                actionPosition="left"
              />
            </ImageListItem>
          </CustomTabPanel>
          <CustomTabPanel value={tab} index={1}>
            <Typography variant="body2">
              {t("preview.menuPlaceholder")}
            </Typography>
          </CustomTabPanel>
        </Box>
      </Box>
    </Box>
  );
};
export default function Preview({
  isOpen,
  setIsOpen,
  restaurant,
}: {
  isOpen: boolean;
  setIsOpen: (value: SetStateAction<boolean>) => void;
  restaurant: Restaurant;
}) {
  const { t } = useTranslation();
  const { uiMode } = useContext(AppContext);
  const toggleDrawer = (newOpen: SetStateAction<boolean>) => () => {
    setIsOpen(newOpen);
  };

  const phoneBgColor = uiMode === UIMode.Light ? "#fff" : "#000";
  const DrawerContent = (
    <Box sx={{ width: 550 }} role="presentation">
      <Container sx={{ padding: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} textAlign="right">
            <IconButton onClick={toggleDrawer(false)}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Typography variant="h6">{t("preview.title")}</Typography>
        <Typography my={1} variant="body2">
          {t("preview.description")}
        </Typography>
        <Box
          width={284}
          height={603}
          padding={1}
          boxShadow="1px 2px 10px #333"
          borderRadius={5}
          margin="40px auto 0"
          bgcolor={phoneBgColor}
        >
          <Box
            border="1px solid #333"
            height="100%"
            width="100%"
            borderRadius={3}
            sx={{ overflowY: "scroll" }}
          >
            <MobilePreview restaurant={restaurant} bgColor={phoneBgColor} />
          </Box>
        </Box>
      </Container>
    </Box>
  );

  return (
    <div>
      <Drawer open={isOpen} onClose={toggleDrawer(false)} anchor="right">
        {DrawerContent}
      </Drawer>
    </div>
  );
}
