import { createContext, Dispatch, SetStateAction } from "react";
import { UIMode } from "../constants";
import { Restaurant } from "../types/Restaurant";

export interface User {
  id: string;
  name: string;
  email: string;
}

interface Context {
  currentUser?: User;
  uiMode?: UIMode;
  restaurant?: Restaurant;
  setCurrentUser?: Dispatch<SetStateAction<User | undefined>>;
  setRestaurant?: Dispatch<SetStateAction<Restaurant | undefined>>;
  setUiMode?: Dispatch<SetStateAction<UIMode>>;
}

export const AppContext = createContext<Context>({
  uiMode: UIMode.Dark,
});
