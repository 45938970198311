import { init } from "i18next";
import Keycloak, { KeycloakConfig } from "keycloak-js";

const initOptions: KeycloakConfig = {
  url: "http://localhost:8080/",
  realm: "ACCESS",
  clientId: "ACCESS",
};

export const keycloak = new Keycloak(initOptions);
interface InitKeycloak {
  keycloak: Keycloak;
  auth: boolean;
}

export interface InitKeycloakParams {
  access_token: string;
  expires_in?: string;
  refresh_expires_in?: string;
  refresh_token: string;
  token_type?: string;
  "not-before-policy"?: string;
  session_state?: string;
  scope?: string;
}

let initialized = false;
export const initKeycloak = async (
  params: InitKeycloakParams,
): Promise<InitKeycloak> => {
  try {
    if (initialized) {
      keycloak.token = params.access_token;
      keycloak.refreshToken = params.refresh_token;
      return { keycloak, auth: keycloak.authenticated! };
    }

    const auth = await keycloak.init({
      // onLoad: "check-sso",
      responseMode: "query",
      // flow: "implicit",
      // useNonce: true,
      token: params.access_token,
      refreshToken: params.refresh_token,
      checkLoginIframe: false,
      // pkceMethod: "S256",
    });

    initialized = true;
    if (!auth) {
      console.info("not Authenticated");
      // keycloak.token = "";
      // window.location.reload();
    } else {
      console.info("Authenticated");
      console.log("Keycloak", keycloak);
      keycloak.onTokenExpired = () => {
        console.log("token expired");
        keycloak.updateToken(70).then((refreshed) => {
          if (refreshed) {
            console.log("token refreshed");
          } else {
            const seconds = Math.round(
              (keycloak.tokenParsed?.exp ?? 0) +
                (keycloak.timeSkew ?? 0) -
                new Date().getTime() / 1000,
            );
            console.log(`token not refreshed, valid for ${seconds} seconds`);
          }
        });
      };
    }
    return { keycloak, auth };
  } catch (error) {
    console.error("Authenticated Failed", error);
    throw error;
  }
};
