import {
  Box,
  Button,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Paper,
  Typography,
} from "@mui/material";
import { t } from "i18next";
import { DragEvent, useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";

export default function FileUploader({
  onFilesSelected,
}: {
  onFilesSelected: (files: File[]) => void;
}) {
  const [files, setFiles] = useState<File[]>([]);
  const [isDragging, setIsDragging] = useState(false);

  const handleFileChange = (event: any) => {
    const selectedFiles: File[] = event.target?.files;
    if (selectedFiles && selectedFiles.length > 0) {
      const newFiles = Array.from(selectedFiles);
      setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    }
  };
  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);
    const droppedFiles = event.dataTransfer?.files;
    if (droppedFiles && droppedFiles.length > 0) {
      const newFiles = Array.from(droppedFiles);
      setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    }
  };
  const handleDrag = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleRemoveFile = (index: number) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  useEffect(() => {
    onFilesSelected(files);
    console.log(files);
  }, [files, onFilesSelected]);

  return (
    <Paper sx={{ padding: 4, marginTop: 2, textAlign: "center" }}>
      <div
        className={`${isDragging ? "border-2 border-dashed border-gray-300" : ""} rounded-md p-14`}
        onDrop={handleDrop}
        onDragEnd={() => setIsDragging(false)}
        onDragOver={handleDrag}
      >
        <>
          <Typography variant="h6">
            {t("fileUpload.uploadImageDesription")}
          </Typography>
          <Typography variant="body1" marginBottom={2}>
            {t("fileUpload.uploadImageSpecs")}
          </Typography>
          <Typography variant="body2">
            {t("fileUpload.uploadImageOrText")}
          </Typography>
          <input
            type="file"
            hidden
            id="browse"
            onChange={handleFileChange}
            accept=".png,.jpg,.jpeg"
            multiple
          />
          <Button
            variant="outlined"
            color="primary"
            type="button"
            sx={{ marginTop: 2, padding: 0 }}
          >
            <label htmlFor="browse" role="button" className="px-4 py-2">
              {t("fileUpload.uploadButtonText")}
            </label>
          </Button>
        </>
      </div>
      <Box marginTop={4}>
        <ImageList cols={4} gap={20}>
          {files.map((file, index) => (
            <ImageListItem key={file.name}>
              <img
                srcSet={URL.createObjectURL(file)}
                src={URL.createObjectURL(file)}
                alt={file.name}
                className="rounded-md"
                height={100}
                loading="lazy"
              />
              <ImageListItemBar
                sx={{
                  borderRadius: "6px 6px 0 0",
                  background:
                    "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
                    "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
                }}
                position="top"
                actionIcon={
                  <IconButton
                    onClick={() => handleRemoveFile(index)}
                    sx={{ color: "#fff" }}
                    aria-label={t("common.delete")}
                  >
                    <DeleteIcon />
                  </IconButton>
                }
                actionPosition="right"
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {files.length > 0 && (
        <Box textAlign="center" marginTop={2}>
          <Button variant="contained" type="button">
            {t("fileUpload.uploadFiles", { count: files.length })}
          </Button>
        </Box>
      )}
    </Paper>
  );
}
