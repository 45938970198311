export enum UIMode {
  Light = "light",
  Dark = "dark",
}

export enum LocalStorageKeys {
  Token = "arp-web-token",
  RefreshToken = "arp-web-refresh-token",
  UiMode = "arp-ui-mode",
}

export enum Languages {
  EN = "en",
  DE = "de",
  ES = "es",
}

export const KEYCLOAK_REALM = "access";
export const PRICE_RANGE_MAX = 5;

export const modalStyle = {
  position: "absolute",
  top: "30%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  py: 2,
  px: 2,
};
